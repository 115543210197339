<template>
  <div class="box">
    <div>
      <h2>{{$t('app.cooperation.investmentRecord')}}</h2>
      <div v-for="(item, index) in list" :key="index">
        <div>
          <span>{{$t('app.cooperation.amount')}}: {{item.money}} USDT</span>
          <span>{{item.time}}</span>
        </div>
        <p v-if="item.state">{{$t('app.cooperation.Finish')}}</p>
      </div>
    </div>
    <router-link :to="{path: '/Cooperation'}"><img src="../../assets/img/fanhui.png" alt=""></router-link>
  </div>
</template>

<script>
import { ethers } from 'ethers'
import Help from '../../contracts/Help.json'
import { initSigner } from '../../utlis/index'
export default {
  data() {
    return {
      address: '',
      contracts: {},
      list: []
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.initTimer)
  },
  computed: {
    coinNumber() {
      let num = '-'
      if (this.UNumber) {
        num = Number(this.UNumber) / 150
      }
      return num
    },
    // 邀请地址
    url() {
      if (!this.address) return '-'
      let url = location.origin + '?referee=' + this.address
      return url
    },
  },
  methods: {
    async init() {
      let { address, provider, signer } = await initSigner()
      this.address = address

      this.contracts.Help = new ethers.Contract(this.$common.Help, Help, provider).connect(signer)

      this.getData()
      clearInterval(this.initTimer)
      this.initTimer = setInterval(() => {
        this.getData()
      }, 20000);
    },
    async getData() {
      if (!this.address) return false
      this.contracts.Help.getList(this.address, 0, 1000).then(res => {
        console.log(res);
        this.list = []
        res[1].forEach((item, index) => {
          this.$set(this.list, index, {
            money: ethers.utils.formatUnits(item, 18),
            time: this.$dayjs(ethers.utils.formatUnits(res[2][index], 0) * 1000).format('YYYY-MM-DD HH:mm:ss'),
            state:res[3][index]
          })
        })
        console.log(this.list);  
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  padding: 1.4rem 0.44rem 0.4rem;
  > a {
    cursor: pointer;
    img {
      width: 20px;
      position: fixed;
      left: 10px;
      top: 75px;
    }
  }
  > div {
    margin: 0 auto;
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
    > div {
      border: 1px solid rgba(255, 255, 255, 0.514);
      padding: 15px 20px 5px 20px;
      margin-bottom: 10px;
      p{
          text-align: center;
          color: #a5eaf3;
        }
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        color: rgba(255, 255, 255, 0.863);
        
        > div {
          padding: 5px 20px;
          background-color: #b886f8;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>